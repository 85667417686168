import React from 'react';
import { MdLens } from 'react-icons/md';
import { Container, Content, Image, Text } from './styles';
import whatIsOpenBanking from '../../assets/whatIsOpenBanking.png';

const WhatsIsOpenBanking: React.FC = () => {
  return (
    <Container>
      <Content>
        <Image>
          <img src={whatIsOpenBanking} alt="O que é Open Finance" />
        </Image>
        <Text>
          <div>
            <MdLens />
            <h2>O que é Open Finance?</h2>
          </div>
          <div>
            <p>
              O Open Finance é um sistema bancário aberto criado pelo Banco
              Central. Com ele, você pode decidir como e por quais instituições
              financeiras suas informações vão ser utilizadas. Tudo isso em um
              sistema rápido e muito seguro.
            </p>
            <p>
              {' '}
              Chegou a hora de sair dos bancos tradicionais e abrir uma Conta
              Digital Midway.
            </p>
          </div>
        </Text>
      </Content>
    </Container>
  );
};

export default WhatsIsOpenBanking;

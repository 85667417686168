import React from 'react';
import appleStore from '../../assets/appleStore.png';
import googlePlay from '../../assets/googlePlay.png';
import { Container, Content } from './styles';

const StoreBadges: React.FC = () => {
  return (
    <Container>
      <Content>
        <div>
          <a href="https://play.google.com/store/apps/details?id=br.com.midway">
            <img src={googlePlay} alt="Google Play" />
          </a>
          <a href="https://apps.apple.com/br/app/midway-app/id1548732480">
            <img src={appleStore} alt="Apple Store" />
          </a>
        </div>
      </Content>
    </Container>
  );
};

export default StoreBadges;

import React from 'react';
import Layout from '../../layout';
import Mobile from '../../components/Mobile';

const MobilePage: React.FC = () => {
  return (
    <Layout>
      <Mobile />
    </Layout>
  );
};

export default MobilePage;

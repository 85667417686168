import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 2rem;
  background: linear-gradient(0deg, #edf7f6 0%, rgba(255, 255, 255, 1) 100%);
  align-items: center;
  padding-bottom: 2rem;
  @media (max-width: 760px) {
    padding-bottom: 2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 96.4rem;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 100%;
  }

  .carousel {
    border-radius: 3rem;
    @media (max-width: 361px) {
      height: auto;
    }
  }

  .carousel .control-dots {
    @media (max-width: 361px) {
      height: auto;
      bottom: 1.5rem;
    }
  }

  .carousel .slider-wrapper {
    height: auto;
  }

  .carousel .control-dots .dot {
    width: 1.2rem;
    height: 1.2rem;
    box-shadow: none;
    margin: 2.9rem 1rem 2rem 0;
    opacity: 100%;
    background-color: white;
    @media (max-width: 361px) {
      margin: 0 0.8rem 0 0.8rem;
    }
  }

  .carousel .control-dots .dot.selected {
    background-color: black;
  }

  .carousel .control-dots {
    margin: 3px 0;
  }

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 100%;
    @media (max-width: 850px) {
      display: none;
    }
  }

  .carousel .control-prev.control-arrow:before {
    margin-top: 14rem;
    margin-left: 3rem;
    content: '';
    border: solid white;
    border-width: 0 0.8rem 0.8rem 0;
    display: inline-block;
    padding: 1.4rem;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .carousel .control-next.control-arrow:before {
    margin-top: 14rem;
    margin-right: 3rem;
    content: '';
    border: solid white;
    border-width: 0 0.9rem 0.9rem 0;
    display: inline-block;
    padding: 1.4rem;
    transform: rotate(-35deg);
    -webkit-transform: rotate(315deg);
  }

  .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 3.2rem;

  h2 {
    margin-left: 1rem;
    font-weight: 700;
    color: var(--petroleum);
    font-size: 2rem;
    @media (max-width: 700px) {
      font-size: 2rem;
      line-height: 2.9rem;
    }
  }
  svg {
    color: var(--violetMidway);
    height: 2rem;
    width: 2rem;
  }
`;

export const CarouselItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const CarouselImage = styled.div`
  height: 15.4rem;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
  }
`;

export const CarouselText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 43.3rem;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5rem 16rem 7rem 16rem;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @media (max-width: 1060px) {
    padding: 5rem 10rem 7rem 10rem;
    margin: 0;
    overflow: hidden;
    background-position: 75% 50%;
  }

  @media (max-width: 850px) {
    padding: 5rem 4rem 6rem 4rem;
    margin: 0;
    overflow: hidden;
    background-position: 75% 50%;
  }

  @media (max-width: 680px) {
    margin: 0;
    background-position: 75% 50%;
  }

  @media (max-width: 361px) {
    min-height: 40rem;
    padding: 3rem 2rem 5.5rem 2rem;
  }
`;

export const HighlightTitle = styled.h4`
    display: flex;
    align-items: center;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 5rem;
    line-height: 6.9rem;
    text-align: left;
    color: #000000;
    text-decoration: none !important;
    text-indent: 6.5rem;

    @media (max-width: 850px) {
      text-indent: 6rem;
      font-size: 5rem;
    }

    @media (max-width: 571px) {
      text-indent: 5rem;
      font-size: 4rem;
      line-height: 5rem;
    }

    @media (max-width: 490px) {
      text-indent: 5rem;
      font-size: 3.5rem;
      line-height: 5rem;
    }

    @media (max-width: 445px) {
      text-indent: 4rem;
      font-size: 3rem;
      line-height: 4rem;
    }

    @media (max-width: 401px) {
      text-indent: 4rem;
      font-size: 2.5rem;
      line-height: 3.5rem;
    }

    @media (max-width: 371px) {
      text-indent: 4rem;
      font-size: 2.5rem;
      line-height: 3.5rem;
    }

    svg {
      top: 21.3rem;
      left: 16.5rem;
      position: absolute;

      @media (max-width: 1060px) {
        top: 21.4rem;
        left: 10rem;
      }

      @media (max-width: 850px) {
        top: 21.4rem;
        left: 4rem;
      }

      @media (max-width: 571px) {
        top: 20.9rem;
        left: 4rem;
      }

      @media (max-width: 490px) {
        top: 21.1rem;
        left: 4rem;
      }

      @media (max-width: 445px) {
        top: 20.8rem;
        left: 4rem;
      }

      @media (max-width: 361px) {
        height: 3rem;
        top: 18.5rem;
        left: 2rem;
      }
    }

    div {
      width: 60px;
    }
  }
`;

export const HighlightDescription = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.6rem;
  color: black;
  line-height: 1.95rem;
  margin-top: -10px;
  text-align: justify;

  @media (max-width: 490px) {
    margin-top: -1.6rem;
    font-size: 1.4rem;
    height: 100%;
  }

  @media (max-width: 361px) {
    margin-top: 0;
  }
`;

export const HighlightButton = styled.div`
  display: flex;
  align-self: center;

  button {
    width: 29rem;
    height: 5rem;
    background: white;
    border-radius: 2.5rem;
    align-self: end;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: var(--greenLight);

    @media (max-width: 490px) {
      width: 27rem;
      height: 4rem;
    }

    @media (max-width: 321px) {
      width: 24rem;
      height: 4rem;
    }
  }
`;

export const Action = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Gradient = styled.div`
  display: flex;
  width: 100%;
  background: #46cbd1;
  background: -moz-linear-gradient(left,  #46cbd1 0%, #e040fb 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  #46cbd1 0%,#e040fb 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  #46cbd1 0%,#e040fb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#46cbd1', endColorstr='#e040fb',GradientType=1 ); /* IE6-9 */
  justify-content: center;
  align-items: center;
  height: 480px;
  padding: 0 2rem;
  @media (max-width: 460px) {
    height: 360px;
    background: #e040fb;
    background: -moz-linear-gradient(top, #e040fb 0%, #46cbd1 99%);
    background: -webkit-linear-gradient(top, #e040fb 0%,#46cbd1 99%);
    background: linear-gradient(to bottom, #e040fb 0%,#46cbd1 99%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e040fb', endColorstr='#46cbd1',GradientType=0 );
 }

  span{
    display:flex;
    justify-content: center;
    align-items: center;
    height:100%;
    width: 96.4rem;
    @media (max-width: 600px) {
      flex-direction: column;
    }

    div:nth-child(1){
      flex:1;
      display:flex;
      height:100%;
      justify-content: center;
      align-items: center;
      @media (max-width: 600px) {
        align-items: flex-end;
      }
      @media (max-width: 460px) {
        align-items: center;
      }
      @media (max-width: 360px) {
        align-items: center;
      }

      h2{
        font-style: normal;
        font-weight: bold;
        font-size: 5rem;
        line-height: 6.1rem;
        color: var(--white);
        @media (max-width: 800px) {
          font-size: 3.5rem;
        }
        @media (max-width: 460px) {
          font-size: 2.4rem;
          line-height: 2.8rem;
        }
      }
    }

    div:nth-child(2){
      flex:1;
      display:flex;
      height:100%;
      justify-content: center;
      align-items: center;    
      @media (max-width: 600px) {
        flex:2;
      }     

      img{
        height: 30rem;
        @media (max-width: 800px) {
          height: 24rem;
        }
        @media (max-width: 600px) {
          height: 20rem;
        }
        @media (max-width: 360px) {
          height: 18rem;
          align-self: center;
        }
      }
    }

  }

}
`;

export const MidwayM = styled.div`
  display: flex;
  img{
    width: 4.5rem;
  }
}
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--gray);
  margin-top: 6.4rem;
  margin-bottom: 7.6rem;
  line-height: 150%;
  width: 100%;
  align-items: center;
  padding: 0 2rem;
  @media (max-width: 600px) {
    align-items: flex-end;
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    width: 96.4rem;
    @media (max-width: 1060px) {
      width: 100%;
    }
  }

  div {
    div:nth-child(2) {
      @media (max-width: 361px) {
        div {
          align-self: center;
        }
      }
    }
  }
`;

export const Paragraph = styled.div`
  display: flex;
  width: inherit;
  justify-content: flex-start;
  margin-bottom: 3.2rem;

  span {
    color: var(--greenLight);
    font-size: 1.6rem;
    font-weight: 600;
    text-decoration: none;
    margin-top: 3.2rem;
    @media (max-width: 361px) {
      font-size: 1.8rem;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 8rem 2rem 8rem 2rem;
  @media (max-width: 361px) {
    margin: 4rem 0 4rem 0;
    padding: 0;
  }
`;

export const Content = styled.div`
  background-color: var(--lightViolet);
  padding: 3.5rem 2rem;
  display: flex;
  gap: 1.6rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1.6rem;
  heigth: 1.87rem;
  width: 96.4rem;
  @media (max-width: 361px) {
    width: 100%;
    margin: 0 2rem 0 2rem;
  }

  h4 {
    font-size: 1.8rem;
    color: var(--petroleum);
    font-weight: 700;
  }

  p {
    color: var(--borderGray);
    font-size: 1.6rem;
    line-height: 24px;
    max-width: 30rem;
    text-align: center;
  }

  a {
    font-size: 1.6rem;
    color: var(--greenLight);
    font-weight: 600;
    text-decoration: none;
  }
`;

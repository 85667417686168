import React from 'react';

import Welcome from '../../components/Welcome';
import WhatIsOpenBanking from '../../components/WhatIsOpenBanking';
import Highlights from '../../components/Highlights';
import PhasesOpenBanking from '../../components/PhasesOpenBanking';
import WhatChanges from '../../components/WhatChanges';

import Layout from '../../layout';
import Doubts from '../../components/Doubts';
import NewsFeed from '../../components/NewsFeed';
import FaqFeed from '../../components/FaqFeed';

const Home: React.FC = () => {
  return (
    <Layout>
      <Welcome />
      <WhatIsOpenBanking />
      <Highlights />
      <PhasesOpenBanking />
      <WhatChanges />
      <NewsFeed />
      <FaqFeed />
      <Doubts />
    </Layout>
  );
};

export default Home;

import React from 'react';
import {
  Container,
  Content,
  Gradient,
  MidwayM,
  Paragraph,
  Text,
} from './styles';
import notFound from '../../assets/notFound.svg';
import midwayM from '../../assets/midwayM.svg';

const Sorry: React.FC = () => {
  return (
    <Container>
      <Content>
        <Gradient>
          <span>
            <div>
              <h2>OPS... ESSA PÁGINA NÃO EXISTE!</h2>
            </div>
            <div>
              <img src={notFound} alt="NotFound" />
            </div>
          </span>
        </Gradient>
        <MidwayM>
          <img src={midwayM} alt="Midway Logo" />
        </MidwayM>
        <Text>
          <div>
            <Paragraph>
              <p>
                Que tal visitar nosso portal e ficar por dentro de todas as
                novidades sobre Open Finance?
              </p>
              <a
                href="https://openbanking.midway.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                Acessar Portal Open Finance {'>'}
              </a>
            </Paragraph>
          </div>
        </Text>
      </Content>
    </Container>
  );
};

export default Sorry;

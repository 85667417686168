import React, { useState, useEffect } from 'react';
import { Container, Content } from './styles';
import News from '../News';
import { api } from '../../services/baseUrl/api';
import Loading from '../Loading';

type IItems = {
  title: string;
  link: string;
  description: string;
  pubDate: string;
  source: string;
  createdBy: string;
  convertedDate: string;
  position: number;
  id: number;
};

const NewsFeed: React.FC = () => {
  const [news, setNews] = useState<IItems[]>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await api.get('/news/enabled');
      if (response?.data) {
        setNews(response.data);
      }
    } catch (err) {
      setHasError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {hasError && <></>}
      {isLoading && !hasError && (
        <Container>
          <Loading />
        </Container>
      )}
      {news && news.length === 0 && <></>}
      {news && news.length > 0 && (
        <Container>
          <Content>
            <div>
              <h2>Últimas Notícias</h2>
            </div>
            <div>
              {news
                .sort((a, b) => a.position - b.position)
                .map((item) => {
                  const year = item.pubDate.substring(0, 4);
                  const month = item.pubDate.substring(5, 7);
                  const day = item.pubDate.substring(8, 10);
                  const newPubDateOrder = `${day}/${month}/${year}`;
                  return (
                    <News
                      title={item.title}
                      link={item.link}
                      description={item.description}
                      pubDate={newPubDateOrder}
                      source={item.source}
                      key={item.id}
                    />
                  );
                })}
            </div>
          </Content>
        </Container>
      )}
    </>
  );
};

export default NewsFeed;

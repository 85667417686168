import React from 'react';
import Layout from '../../layout';
import Sorry from '../../components/Sorry';

const NotFound: React.FC = () => {
  return (
    <Layout>
      <Sorry />
    </Layout>
  );
};

export default NotFound;

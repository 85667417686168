import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h3 {
    margin-right: 1.6rem;
    margin-bottom: 1.6rem;
  }

  > div {
    display: flex;
    gap: 1.7rem;
  }

  img {
    height: 3.5rem;
  }

  @media (max-width: 740px) {
    font-size: 1.4rem;
  }

  @media (max-width: 361px) {
    > div {
      img {
        height: 3.6rem;
      }
    }
  }
`;

import axios from 'axios';
import getOafHeaders from '../oAuthToken/OAuthToken';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

// toda requisição recebe esses tokens
api.interceptors.request.use(async (config) => {
  const oafHeaders = await getOafHeaders();
  const newConfig = config;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  newConfig.headers = {
    ...config.headers,
    ...oafHeaders,
  };

  return config;
});

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  padding: 8rem 2rem 8rem 2rem;
  background-color: var(--lightViolet);
  @media (max-width: 520px) {
    padding: 4rem 2rem 2rem 2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 96.4rem;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: 100%;
  }

  h2 {
    margin-bottom: 4.8rem;
    font-weight: 700;
    color: var(--petroleum);
    font-size: 3rem;
    text-align: left;
    @media (max-width: 530px) {
      font-size: 2.2rem;
    }
  }
`;

import React, { useState, useEffect } from 'react';
import { BsFillCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs';
import { Carousel } from 'react-responsive-carousel';
import {
  Container,
  Content,
  CarouselItem,
  CarouselImage,
  CarouselText,
  Title,
  HighlightTitle,
  HighlightDescription,
  HighlightButton,
} from './styles';
import { api } from '../../services/baseUrl/api';
import Loading from '../Loading';
import greenGirl from '../../assets/greenGirl.png';
import purpleGirl from '../../assets/purpleGirl.png';
import orangeGirl from '../../assets/yellowGirl.png';
import greenBackground from '../../assets/backgroundGreen.jpg';
import purpleBackground from '../../assets/backgroundPurple.jpg';
import orangeBackground from '../../assets/backgroundOrange.jpg';

type IHighlight = {
  id: number;
  title: string;
  link: string;
  description: string;
  image: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  enabled: boolean;
  disabledBy: string;
  disabledAt: string;
  position: number;
};

const Highlights: React.FC = () => {
  const [highlight, setHighlight] = useState<IHighlight[]>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [model, setModel] = useState<number>(0);
  const [theme, setTheme] = useState<number>(0);
  const [background, setBackground] = useState<number>(0);

  const models = [greenGirl, purpleGirl, orangeGirl];
  const themes = ['#03cfc3', '#7F97E0', '#FFB400'];
  const backgrounds = [greenBackground, purpleBackground, orangeBackground];

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onChange = function teste() {
    if (model === 0 && theme === 0 && background === 0) {
      setModel(1);
      setTheme(1);
      setBackground(1);
    }
    if (model === 1 && theme === 1 && background === 1) {
      setModel(2);
      setTheme(2);
      setBackground(2);
    }
    if (model === 2 && theme === 2 && background === 2) {
      setModel(0);
      setTheme(0);
      setBackground(0);
    }
  };

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await api.get('/highlight/enabled');
      if (response?.data) {
        setHighlight(response.data);
      }
    } catch (err) {
      setHasError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {hasError && <></>}
      {isLoading && !hasError && (
        <Container>
          <Loading />
        </Container>
      )}
      {highlight && highlight.length === 0 && <></>}
      {highlight && highlight.length > 0 && (
        <Container>
          <Content>
            <Title>
              <BsFillCircleFill />
              <h2>Destaques Midway</h2>
            </Title>
            <div>
              <Carousel
                showArrows
                infiniteLoop
                autoPlay
                showStatus={false}
                showThumbs={false}
                centerMode={false}
                interval={8000}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={onChange}
              >
                {highlight
                  .sort((a, b) => a.position - b.position)
                  .map((item) => (
                    <CarouselItem key="id">
                      <CarouselImage>
                        <img
                          style={{
                            backgroundImage: `url(${models[model]})`,
                            backgroundColor: `${themes[theme]}`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center top',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            border: 'none',
                          }}
                          alt=""
                        />
                      </CarouselImage>
                      <CarouselText
                        style={{
                          backgroundImage: `url(${backgrounds[background]})`,
                        }}
                      >
                        <HighlightTitle>
                          <BsFillArrowRightCircleFill />
                          {item.title.substring(0, 30)}
                        </HighlightTitle>
                        <HighlightDescription>
                          {item.description.substring(0, 220)}
                        </HighlightDescription>
                        <HighlightButton>
                          {item.link && (
                            <a
                              href={`${item.link}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button type="button">Saiba Mais</button>
                            </a>
                          )}
                        </HighlightButton>
                      </CarouselText>
                    </CarouselItem>
                  ))}
              </Carousel>
            </div>
          </Content>
        </Container>
      )}
    </>
  );
};

export default Highlights;

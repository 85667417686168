import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  background-color: var(--lightNewTifanny);
  width: 100%;
  padding: 0 2rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
  padding: 5rem 2rem;
  width: 96.4rem;
  @media (max-width: 480px) {
    padding: 4rem 0 4rem 0;
  }

  h2 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--petroleum);
    line-height: 1.5;
    @media (max-width: 480px) {
      text-align: center;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.4rem;
    color: var(--blackMidway);
    text-align: center;
    @media (max-width: 480px) {
      text-align: left;
      line-height: 2.4rem;
      font-size: 1.5rem;
    }
  }

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: var(--blackMidway);
    text-align: center;
    width: 100%;
    max-width: 65rem;
    @media (max-width: 480px) {
      text-align: left;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
`;

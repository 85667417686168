import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 48.7rem;
  justify-content: center;
  padding: 5rem 2rem 0 2rem;
  background-color: var(--newLightGray);
  @media (max-width: 996px) {
    height: 50rem;
  }
  @media (max-width: 965px) {
    height: 43rem;
    padding: 5rem 2rem 0 2rem;
    .carousel .slider-wrapper {
      padding-bottom: 2rem;
    }
  }
  @media (max-width: 780px) {
    .carousel .slider-wrapper {
      padding-bottom: 1rem;
    }
  }
  @media (max-width: 580px) {
    .carousel .slider-wrapper {
      padding-bottom: 0;
    }
  }
  @media (max-width: 508px) {
    height: 46rem;
    .carousel .slider-wrapper {
      padding-bottom: 0;
    }
  }
  @media (max-width: 412px) {
    height: 48rem;
  }
  @media (max-width: 388px) {
    height: 51rem;
  }
  @media (max-width: 361px) {
    height: 48.5rem;
  }
  @media (max-width: 321px) {
    height: 51rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 96.4rem;
  height: auto;
  @media (max-width: 1000px) {
    width: 100%;
  }

  h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #00595b;
    margin-bottom: 3.2rem;
  }

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 100%;
    @media (max-width: 970px) {
      display: none;
    }
  }

  .carousel .control-dots {
    @media (min-width: 968px) {
      display: none;
    }
    @media (max-width: 361px) {
      height: auto;
      bottom: 1.5rem;
    }
  }

  .carousel .control-dots .dot {
    width: 1.2rem;
    height: 1.2rem;
    box-shadow: none;
    margin: 2.9rem 1rem 2rem 0;
    opacity: 100%;
    background-color: lightgray;
    @media (max-width: 967px) {
      margin: 0 0.8rem 0 0.8rem;
    }
    @media (max-width: 361px) {
      margin: 0 0.8rem 0 0.8rem;
    }
  }

  .carousel .control-dots .dot.selected {
    background-color: black;
  }

  .carousel .control-dots {
    margin: 0.3rem 0;
  }
`;

export const StagesCarousel = styled.div`
  display: flex;
  max-width: 96.4rem;
  @media (max-width: 361px) {
    width: 100%;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    margin-bottom: 4rem;
    padding: 0 4rem;
    @media (max-width: 965px) {
      grid-template-columns: 1fr;
      padding: 0;
    }
  }
`;

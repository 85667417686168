import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 2rem 2rem 2rem;
`;

export const BlackBoard = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--blackMidway);
  border-radius: 2rem;
  padding-top: 4.7rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 96.4rem;
  @media (max-width: 1050px) {
    padding: 0 3rem 0 3rem;
  }
`;

export const Logo = styled.div``;

export const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 4rem 0 4rem 0;
  height: 100%;
  gap: 10rem;

  @media (max-width: 1050px) {
    flex-direction: column;
    padding: 2rem 0 0 0;
    gap: 5rem;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    padding: 2rem 0 4rem 0;
    gap: 2rem;
  }

  h3 {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1.6rem;
    color: white;
  }

  h4 {
    font-size: 1.2rem;
    color: var(--footerGray);
    font-weight: 400;
    margin-bottom: 0.8rem;
    line-height: 1.6rem;
    width: auto;
    @media (max-width: 480px) {
      text-align: justify;
    }
  }

  h5 {
    color: var(--lightGray);
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    margin-bottom: 2rem;
    color: var(--footerGray);
  }

  a {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    color: var(--white);
  }
`;

export const Phones = styled.div`
  flex: 1;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const Email = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: space-between;

  svg {
    font-size: 2.5rem;
  }

  > div {
    flex: 1;
    margin-bottom: 0.32rem;
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    h4 {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }

  @media (max-width: 480px) {
    > div:nth-child(1) {
      order: 2;
      h3 {
        display: none;
      }
    }

    > div:nth-child(2) {
      order: 1;
      display: flex;
      margin-bottom: 4rem;
      height: auto;
    }

    > div:nth-child(3) {
      order: 3;
    }
  }
`;

export const Ombudsman = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: auto;
  @media (max-width: 1050px) {
    padding-bottom: 2rem;
  }
  @media (max-width: 480px) {
    padding-bottom: 0;
  }

  a {
    text-decoration: none;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 10px 12px;

    width: 257px;
    height: 40px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    border: 1px solid #ffffff;
    border-radius: 25px;
    background-color: transparent;
    color: white;
    @media (max-width: 480px) {
      margin: 0;
      padding: 0;
    }
  }

  button:hover {
    background-color: white;
    color: black;
  }

  @media (max-width: 480px) {
    div:nth-child(1) {
      display: none;
    }
    div:nth-child(2) {
      display: none;
    }
    div:nth-child(3) {
      display: flex;
      justify-content: center;
    }
  }
`;

export const SocialMedias = styled.div`
  display: column;
  margin-top: 2rem;

  a {
    margin-right: 1rem;
  }

  @media (max-width: 480px) {
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
`;

export const FooterCopyright = styled.div`
  height: 6.3rem;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--borderGray);
  margin-top: auto;
  color: var(--footerGray);
  font-size: 1.2rem;
  font-weight: 400;
`;

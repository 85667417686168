import React, { useState, useEffect } from 'react';
import { Container, Content, Question, Answer, Feed } from './styles';
import { api } from '../../services/baseUrl/api';
import Loading from '../Loading';
import downArrow from '../../assets/downArrow.png';
import upArrow from '../../assets/upArrow.png';
import downCircleArrow from '../../assets/downCircleArrow.png';
import upCircleArrow from '../../assets/upCircleArrow.png';

type IItems = {
  id: number;
  question: string;
  answer: string;
  position: number;
};

const FaqFeed: React.FC = () => {
  const [faq, setFaq] = useState<IItems[]>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [clicked, setClicked] = useState(-1);
  const [visible, setVisible] = useState<number>(5);
  const [showLess, setShowLess] = useState(false);

  const handleToggle = (question: any) => {
    if (clicked === question) {
      setClicked(question + 500);
    } else {
      setClicked(question);
    }
  };

  const showMoreItems = () => {
    if (faq != null) {
      setVisible(faq.length);
    }
    setShowLess(true);
  };

  const showLessItems = () => {
    setVisible(5);
    setShowLess(false);
  };

  const getPageSize = () => {
    setVisible(5);
  };

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);
    getPageSize();

    try {
      const response = await api.get('/faq/enabled');
      if (response?.data) {
        setFaq(response.data);
      }
    } catch (err) {
      // eslint-disable-next-line no-console

      setHasError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {hasError && <></>}
      {isLoading && !hasError && (
        <Container>
          <Loading />
        </Container>
      )}
      {faq && faq.length === 0 && <></>}
      {faq && faq.length > 0 && (
        <Container>
          <Content>
            <section>
              <h2>Perguntas Frequentes</h2>
            </section>
            <section>
              <Feed key="1">
                {faq &&
                  faq
                    ?.sort((a, b) => a.position - b.position)
                    .slice(0, visible)
                    .map((item, i) => {
                      return (
                        <div key={item.id}>
                          <Question
                            key={`${item.id}`}
                            role="button"
                            aria-hidden="true"
                            data-testid={`action-${item.id}`}
                            onClick={() => handleToggle(i)}
                          >
                            <span>
                              <h4>{item.question}</h4>
                            </span>
                            <span>
                              {clicked === i ? (
                                <img src={upCircleArrow} alt="" />
                              ) : (
                                <img src={downCircleArrow} alt="" />
                              )}
                            </span>
                          </Question>
                          {clicked === i && (
                            <Answer key={`${item.answer}`}>
                              <p>{item.answer}</p>
                            </Answer>
                          )}
                        </div>
                      );
                    })}
              </Feed>
            </section>
            <section>
              {showLess ? (
                <button
                  type="button"
                  data-testid="show less"
                  onClick={showLessItems}
                >
                  <h3>Ver menos perguntas</h3>
                  <img src={upArrow} alt="Seta" />
                </button>
              ) : (
                <button
                  type="button"
                  data-testid="show more"
                  onClick={showMoreItems}
                >
                  <h3>Ver mais perguntas</h3>
                  <img src={downArrow} alt="Seta" />
                </button>
              )}
            </section>
          </Content>
        </Container>
      )}
    </>
  );
};

export default FaqFeed;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: auto;
  padding: 10rem 2rem 7rem 2rem;
  @media (max-width: 700px) {
    padding: 17rem 2rem 2rem 2rem;
  }
  @media (max-width: 412px) {
    padding: 12rem 2rem 2rem 2rem;
  }
  @media (max-width: 361px) {
    padding: 10rem 2rem 2rem 2rem;
  }
  @media (max-width: 321px) {
    padding: 10rem 2rem 4rem 2rem;
  }
}

`;

export const Content = styled.div`
  display: flex;
  width: 96.4rem;
  gap: 5rem;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
    gap: 1rem;
  }

  @media (max-width: 361px) {
    flex-direction: column-reverse;
    padding: 3.5rem 2rem 0 2rem;
    text-align: center;
    align-items: center;
    gap: 0;
  }

  @media (max-width: 321px) {
    flex-direction: column-reverse;
    padding: 0;
    text-align: center;
    align-items: center;
    gap: 2rem;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 321px) {
    padding: 0;
  }

  > div {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 1.6rem;

    svg {
      color: #8097e1;
      height: 2.4rem;
      width: 2.4rem;
      margin-right: 1.2rem;
    }

    h2 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 2rem;
      line-height: 2.4rem;
      color: #00595b;
      text-align: left;
      padding-top: 0.1rem;

      @media (max-width: 361px) {
        font-size: 2rem;
        line-height: 2.9rem;
      }

      @media (max-width: 321px) {
        font-size: 1.8rem;
      }
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 150%;
      color: var(--borderGray);
      width: 100%;

      @media (max-width: 700px) {
        text-align: justify;
        font-size: 1.4rem;
      }

      @media (max-width: 361px) {
        font-size: 1.4rem;
        text-align: left;
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;

    p:nth-child(1) {
      margin-bottom: 1.6rem;
    }
  }

  > div:nth-child(3) {
    color: var(--borderGray);
    margin: 0;

    @media (max-width: 361px) {
      > div {
        justify-content: flex-start;
      }
    }
  }
`;

export const Image = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  img {
    width: 41.7rem;
    height: 27.8rem;
    border-radius: 2rem;

    @media (max-width: 850px) {
      width: 31.7rem;
      height: 21rem;
      margin: 2.4rem 0 2.4rem 0;
    }

    @media (max-width: 630px) {
      width: 31.7rem;
      height: 20rem;
    }

    @media (max-width: 361px) {
      width: 32rem;
      height: 19.2rem;
      margin: 2.4rem 0 2.4rem 0;
    }

    @media (max-width: 321px) {
      width: 100%;
      margin: 0 2rem 0 2rem;
    }
  }
`;

import React from 'react';
import {
  Container,
  Content,
  Gradient,
  MidwayM,
  Paragraph,
  Text,
} from './styles';
import mobile from '../../assets/mobile_error.svg';
import midwayM from '../../assets/midwayM.svg';
import StoreBadges from '../StoreBadges';

const Mobile: React.FC = () => {
  return (
    <Container>
      <Content>
        <Gradient>
          <span>
            <div>
              <h2>ACESSO POR DISPOSITIVO MÓVEL</h2>
            </div>
            <div>
              <img src={mobile} alt="Mobile" />
            </div>
          </span>
        </Gradient>
        <MidwayM>
          <img src={midwayM} alt="Midway Logo" />
        </MidwayM>
        <Text>
          <div>
            <Paragraph>
              <p>
                Essa transação deve ser efetuada atraves do seu aplicativo{' '}
                <span>Midway</span> em um dispositivo móvel (celular ou tablet).
              </p>
            </Paragraph>
            <StoreBadges />
          </div>
        </Text>
      </Content>
    </Container>
  );
};

export default Mobile;

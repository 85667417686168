import React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import { Carousel } from 'react-responsive-carousel';
import { Content, NextArrow } from './styles';

interface CarouselProps {
  children: any;
}

const CardsCarousel: React.FC<CarouselProps> = ({ children }) => {
  return (
    <Content>
      <Carousel
        showStatus={false}
        showThumbs={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) => (
          <NextArrow
            type="button"
            onClick={onClickHandler}
            title={label}
            disabled={!hasPrev}
          >
            <MdKeyboardArrowLeft />
          </NextArrow>
        )}
        renderArrowNext={(onClickHandler, hasNext, label) => (
          <NextArrow
            type="button"
            onClick={onClickHandler}
            title={label}
            disabled={!hasNext}
            style={{ right: '0rem' }}
          >
            <MdKeyboardArrowRight />
          </NextArrow>
        )}
      >
        {children}
      </Carousel>
    </Content>
  );
};

export default CardsCarousel;

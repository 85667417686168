import React from 'react';
import { Container } from './styles';

interface StageProps {
  stage: {
    icon: JSX.Element;
    stage: string;
    title: string;
    date: string;
    description: string;
  };
}

const Stage: React.FC<StageProps> = ({ stage }) => {
  return (
    <Container>
      {stage.icon}
      <h3>
        <span>Fase {stage.stage}</span> - {stage.title}
      </h3>
      {/* <h5>A partir de {stage.date}</h5> */}
      <p>{stage.description}</p>
    </Container>
  );
};

export default Stage;

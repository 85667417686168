import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ShadowBox = styled.a`
  display: flex;
  width: 96.4rem;
  min-height: 13.9rem;
  background: white;
  padding: 2.4rem 3.2rem;
  margin: 0 0 2.4rem 0;
  box-shadow: 0 1.5rem 2.5rem -1.5rem rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  cursor: pointer;
  text-decoration: none;
  align-items: center;
  gap: 5rem;

  @media (max-width: 400px) {
    gap: 1rem;
  }

  @media (max-width: 730px) {
    padding: 1.6rem 1.4rem;
    min-height: 10rem;
    margin: 0 0 3.5rem 0;
  }
`;

export const Subtitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h4 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: left;
    color: var(--lightGreen);
    @media (max-width: 530px) {
      font-size: 1.6rem;
      line-height: 1.4;
    }
  }

  p {
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: var(--paragraph);
    margin: 0.8rem 0;
    @media (max-width: 520px) {
      margin: 2rem 0;
      font-size: 1.5rem;
    }
  }
`;

export const Description = styled.div`
  p {
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: var(--paragraph);
    margin: 0.8rem 0;
    @media (max-width: 730px) {
      display: none;
    }
  }
`;

export const Horizontal = styled.div`
  display: flex;
  @media (max-width: 730px) {
    margin-top: 0.8rem;
  }

  p {
    color: var(--gray);
    font-size: 1.2rem;
    margin: 0;
    @media (max-width: 730px) {
      font-size: 1.4rem;
      line-height: 1.4;
    }
    @media (max-width: 530px) {
      font-size: 1.4rem;
      line-height: 1.4;
    }
  }

  p:nth-child(2) {
    margin-left: 10px;
  }

  p:nth-child(2)::before {
    margin-right: 8px;
    content: '-';
  }
`;

export const Icon = styled.div`
  img {
    @media (max-width: 730px) {
      height: 4rem;
      width: 4rem;
    }
    @media (max-width: 530px) {
      height: 2.8rem;
      width: 2.8rem;
    }
  }
`;

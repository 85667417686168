import React from 'react';
import { Container, Content } from './styles';
import headerLogoRiachu from '../../assets/headerLogoRiachu.png';
import headerLogoMidway from '../../assets/headerLogoMidway.png';

const Header: React.FC = () => {
  return (
    <Container>
      <Content>
        <a href="https://www.midway.com.br/" target="_blank" rel="noreferrer">
          <img className="midway" src={headerLogoMidway} alt="Midway" />
        </a>
        <a
          href="https://www.riachuelo.com.br/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="riachu" src={headerLogoRiachu} alt="Riachuelo" />
        </a>
      </Content>
    </Container>
  );
};

export default Header;

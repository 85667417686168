import axios from 'axios';
import { Buffer } from 'buffer';
import { JSEncrypt } from 'jsencrypt';

interface TokenProps {
  RefreshToken?: any;
  ExpiresAt?: number;
  IdToken?: string;
  TokenType?: string;
}

// calcula qdo o token vai expirar
function calculateExpiresAt(expiresIn: number) {
  const expiresAt = new Date();
  expiresAt.setSeconds(expiresAt.getSeconds() + (expiresIn - 60)); // pega o tempo q disse q vai expirar e tira 30 segundos de folga
  return expiresAt.getTime();
}

async function refreshToken(token: TokenProps) {
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_OAF_APPKEY || '',
    },
  };

  const payload = {
    REFRESH_TOKEN: token.RefreshToken,
  };

  const oafAuthApiUrl =
    process.env.REACT_APP_OAF_AUTHAPIURL || 'http://localhost';
  const oafAppId = process.env.REACT_APP_OAF_APPLICATIONID || 1;

  const result = await axios
    .post(`${oafAuthApiUrl}/${oafAppId}/refresh-token`, payload, headers)
    .then((response) => {
      return response.data;
    });

  const newToken = {
    ...result,
    RefreshToken: token.RefreshToken,
    ExpiresAt: calculateExpiresAt(result.ExpiresIn),
  };

  return newToken;
}

function saveTokenInSession(value: TokenProps) {
  sessionStorage.setItem('token', JSON.stringify(value));
}

// retorna usuário e senha criptografados com chave pública
function encryptUserPwd(login: any) {
  const publicKey = process.env.REACT_APP_OAF_PUBLICKEY || '';

  // pega a chave pública
  if (publicKey) {
    const pubkeyAsUtf8 = Buffer.from(publicKey, 'base64').toString('utf8');

    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(pubkeyAsUtf8);

    const encrypted = encrypt.encrypt(JSON.stringify(login));

    return encrypted;
  }
  return '';
}

async function createNewToken() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_OAF_APPKEY || '',
    },
  };

  const uidPwd = {
    USERNAME: process.env.REACT_APP_OAF_USERNAME || '',
    PASSWORD: process.env.REACT_APP_OAF_PASSWORD || '',
  };

  // pega usuario e senha e encripta com uma chave pública
  const payload = {
    value: encryptUserPwd(uidPwd),
  };

  const oafAuthApiUrl =
    process.env.REACT_APP_OAF_AUTHAPIURL || 'http://localhost';
  const oafAppId = process.env.REACT_APP_OAF_APPLICATIONID || 1;

  // faz um POST na api get-token e recebe um token
  const result = await axios
    .post(`${oafAuthApiUrl}/${oafAppId}/get-token`, payload, config)
    .then((response) => {
      return response.data;
    });

  // deste token recebido, calcula quando ele vai expirar
  const newToken = {
    ...result,
    ExpiresAt: calculateExpiresAt(result.ExpiresIn),
  };

  // retorna novo token c data de expiração calculada
  return newToken;
}

function getTokenFromSession() {
  const saved = sessionStorage.getItem('token'); // Toda vez que um token é gerado, é guardado no session token
  const parsed = saved && JSON.parse(saved);

  return parsed;
}

async function getToken() {
  let token = getTokenFromSession(); // tenta recuperar o token da session storage

  // 1° validação
  if (token?.IdToken === undefined) {
    // se o token não existe...
    token = await createNewToken(); // precisamos criar um token
    saveTokenInSession(token); // salva token criado na session storage do browser

    // 2° validação: o instante atual é maior do que o momento que tinha dito q expiraria?
  } else if (
    token?.ExpiresAt &&
    Number(new Date().getTime()) > Number(new Date(token.ExpiresAt))
  ) {
    // Se for verdade vamos gerar um refresh token
    token = await refreshToken(token);
    saveTokenInSession(token);
  }

  // devolve um idToken do tipo bearer
  const result = `${token?.TokenType} ${token?.IdToken}`;

  return result;
}

// headers que incluimos em todas as requisições
async function getOafHeaders() {
  return {
    'x-app-token': await getToken(),
    'x-api-key': process.env.REACT_APP_OAF_APPKEY || '',
  };
}

export default getOafHeaders;

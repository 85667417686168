import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 7rem;
  padding: 0 2rem 0 2rem;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 96.4rem;
  color: var(--white);

  .riachu {
    height: 1.3rem;
  }

  .midway {
    height: 3.6rem;
    margin-right: 2rem;
  }

  @media (max-width: 361px) {
    .riachu {
      height: 0.95rem;
    }

    .midway {
      height: 2.7rem;
      margin-right: 2rem;
    }
  }
`;

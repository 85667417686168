import React from 'react';

import {
  GrLinkedinOption,
  GrInstagram,
  GrFacebookOption,
} from 'react-icons/gr';

import {
  Container,
  BlackBoard,
  Content,
  Logo,
  Columns,
  Phones,
  Email,
  Ombudsman,
  SocialMedias,
  FooterCopyright,
} from './styles';

import midwayFooter from '../../assets/midwayFooter.png';
import StoreBadges from '../StoreBadges';

const Footer: React.FC = () => {
  return (
    <Container>
      <BlackBoard>
        <Content>
          <Logo>
            <img
              width="109px"
              height="36px"
              src={midwayFooter}
              alt="FooterLogo"
            />
          </Logo>

          <Columns>
            <Phones>
              <h3>Telefones Cartão Riachuelo</h3>
              <h4>Capitais e Regiões Metropolitanas</h4>
              <h5>3003 4342</h5>
              <h4>Demais localidades</h4>
              <h5>0800 701 4342</h5>
              <h4>SAC (24h e 7 dias por semana)</h4>
              <h5>3003 4342</h5>
              <h4>SAC Deficientes auditivos (24h e 7 dias por semana)</h4>
              <h5>3003 4342</h5>
            </Phones>

            <Email>
              <div>
                <h3>E-mail</h3>
                <h4>
                  Caro cliente, antes de entrar em contato por e-mail, visite
                  nossa página de{' '}
                  <a
                    href="https://www.midway.com.br/atendimento"
                    style={{
                      fontWeight: 'bold',
                      textDecorationLine: 'underline',
                    }}
                  >
                    Dúvidas Frequentes
                  </a>
                  , caso não encontre sua dúvida preencha nosso formulário{' '}
                  <a
                    href="https://www.midway.com.br/atendimento"
                    style={{
                      fontWeight: 'bold',
                      textDecorationLine: 'underline',
                    }}
                  >
                    clicando aqui.
                  </a>
                </h4>
              </div>
              <div>
                <h4>Baixe nosso app</h4>
                <StoreBadges />
              </div>
              <div>
                <SocialMedias>
                  <h3>Siga nas nossas redes</h3>
                  <div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/midway.digital/"
                    >
                      <GrInstagram />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/midwaydigital"
                    >
                      <GrFacebookOption />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/company/financeiramidway/"
                    >
                      <GrLinkedinOption />
                    </a>
                  </div>
                </SocialMedias>
              </div>
            </Email>

            <Ombudsman>
              <div>
                <h3>Ouvidoria</h3>
                <h5>0800 727 3255</h5>
              </div>
              <div>
                <p>
                  Para atendimento, é necessário informar o nº do protocolo de
                  atendimento de sua demanda registrada junto à Central de
                  Atendimento.
                  <br />
                  <br />
                  De 2ª a 6ª feira, das 10h às 16h, exceto feriados. A
                  Ouvidoria, instituída conforme Resolução CMN 4860/2020, é um
                  canal de última instância, atuando de forma isenta nos
                  assuntos financeiros relacionados à Midway, já tratados pelos
                  canal de atendimento, sem solução satisfatória.
                </p>
              </div>
              <div>
                <a
                  href="https://www.midway.com.br/atendimento"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button type="button">Central de atendimento</button>
                </a>
              </div>
            </Ombudsman>
          </Columns>

          <FooterCopyright>
            <p>Copyright @ 2021 todos os direitos reservados - Midway</p>
          </FooterCopyright>
        </Content>
      </BlackBoard>
    </Container>
  );
};

export default Footer;

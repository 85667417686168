import React from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { MdAccountBalance } from 'react-icons/md';

import {
  FiRepeat,
  FiGrid,
  FiUser,
  FiUserCheck,
  FiCreditCard,
} from 'react-icons/fi';

import { FaHandHoldingUsd } from 'react-icons/fa';
import { BsXDiamondFill } from 'react-icons/bs';

import CardsCarousel from '../CardsCarousel';
import Stage from '../Stage';

import { Container, Content, StagesCarousel } from './styles';

const PhasesOpenBanking: React.FC = () => {
  const stages = [
    {
      icon: <FiGrid />,
      stage: '1',
      title: 'Dados de Produtos e Serviços',
      date: '01/02/2021',
      description:
        'As instituições vão poder compartilhar: informações sobre pacote de produtos, serviços e taxas.',
    },
    {
      icon: <FiUserCheck />,
      stage: '2',
      title: 'Dados cadastrais e transacionais',
      date: '13/08/2021',
      description:
        'As instituições vão poder compartilhar e portabilizar seus dados com outras instituições, quando você autorizar.',
    },
    {
      icon: <BsXDiamondFill />,
      stage: '3',
      title: 'Início do pagamento por PIX',
      date: '30/08/2021',
      description:
        'Você poderá realizar transações utilizando o PIX na instituição que preferir.',
    },
    {
      icon: <MdAccountBalance />,
      stage: '4',
      title: 'Ampliação dos dados de produtos e serviços',
      date: '15/12/2021',
      description:
        'As instituições vão poder compartilhar: informações sobre produtos, serviços e taxas de câmbio, investimentos, seguros e previdência privada.',
    },
    {
      icon: <FiRepeat />,
      stage: '5',
      title: 'Início dos pagamentos por TED /TEF',
      date: '15/02/2022',
      description:
        'Você poderá realizar transações utilizando o TED ou TEF na instituição que preferir.',
    },
    {
      icon: <FaHandHoldingUsd />,
      stage: '6',
      title: 'Início das propostas de crédito',
      date: '30/03/2022',
      description:
        'Aqui você pode compartilhar seu histórico de informações financeiras, e facilitar a transferência e propostas de crédito.',
    },
    {
      icon: <FiUser />,
      stage: '7',
      title: 'Ampliação dos dados transacionais',
      date: '31/05/2022',
      description:
        'As instituições vão poder compartilhar: informações sobre produtos, serviços e taxas de câmbio, investimentos, seguros e previdência privada, quando você autorizar.',
    },
    {
      icon: <FiCreditCard />,
      stage: '8',
      title: 'Início do pagamento por boleto',
      date: '31/06/2022',
      description:
        'Você poderá realizar pagamentos por boleto ou consultar serviços bancários na instituição que preferir, sem precisar abrir uma conta.',
    },
    {
      icon: <FiGrid />,
      stage: '9',
      title: 'Início do pagamento por débito em conta',
      date: '30/08/2022',
      description:
        'Você poderá realizar transações utilizando o débito em conta na instituição que preferir.',
    },
  ];

  const mobile = useMediaPredicate('(max-width: 965px)');

  return (
    <Container>
      <Content>
        <h2>Fases do Open Finance</h2>
        <StagesCarousel>
          {!mobile ? (
            <CardsCarousel>
              <div className="grid">
                <Stage stage={stages[0]} />
                <Stage stage={stages[1]} />
                <Stage stage={stages[2]} />
              </div>
              <div className="grid">
                <Stage stage={stages[3]} />
                <Stage stage={stages[4]} />
                <Stage stage={stages[5]} />
              </div>
              <div className="grid">
                <Stage stage={stages[6]} />
                <Stage stage={stages[7]} />
                <Stage stage={stages[8]} />
              </div>
            </CardsCarousel>
          ) : (
            <CardsCarousel>
              {stages.map((stage) => (
                <div className="grid">
                  <Stage stage={stage} />
                </div>
              ))}
            </CardsCarousel>
          )}
        </StagesCarousel>
      </Content>
    </Container>
  );
};

export default PhasesOpenBanking;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  min-height: 31rem;
  background: linear-gradient(0deg, #edf7f6 0%, rgba(255, 255, 255, 1) 100%);
  padding: 2rem 2rem 0 2rem;
  @media (max-width: 700px) {
    height: 30rem;
  }
  @media (max-width: 412px) {
    height: 34rem;
  }
  @media (max-width: 361px) {
    height: 25rem;
  }
  @media (max-width: 321px) {
    height: 33rem;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 96.4rem;
  gap: 4rem;
  padding-top: 2rem;

  @media (max-width: 700px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 0;
  }
`;

export const Logo = styled.div`

  color: var(--petroleum);

  h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.4rem;

    span:nth-child(1) {
      margin-bottom: 2rem;
      width: 22rem;
    }

    span:nth-child(2) {
      display: flex;
      margin-bottom: 1.6rem;
      @media (max-width: 700px) {
        justify-content: center;
      }

      div:nth-child(1) {
        @media (max-width: 700px) {
          margin-left: 1.6rem;
        }
      }

      div:nth-child(2) {
        display: flex;
        align-items: center;
        svg {
          margin-left: 1rem;
          color: #8097e1;
          height: 2.4rem;
          width: 2.4rem;
        }
      }
    }
`;

export const Text = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  h2 {
    font-size: 2.8rem;
    line-height: 3.4rem;
    color: var(--petroleum);
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    color: var(--borderGray);
    width: 100%;
    max-width: 32rem;
  }

  @media (max-width: 700px) {
    align-items: center;
    h1 {
      font-size: 2.4rem;
      line-height: 2.9rem;
    }

    h2 {
      font-size: 2.4rem;
      line-height: 2.9rem;
    }

    p {
      font-size: 1.4rem;
      padding: 0 2rem;
    }
  }
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  svg {
    color: var(--violetMidway);
    font-size: 2rem;
  }

  img {
    width: 51.2rem;
    border-radius: 2rem;
    margin-bottom: -7rem;
    @media (max-width: 850px) {
      width: 31.2rem;
    }
    @media (max-width: 700px) {
      width: 36rem;
      margin: 3rem 0 0 0;
    }
    @media (max-width: 412px) {
      width: 100%;
    }
  }
`;

import React from 'react';
import {
  Container,
  Subtitle,
  Icon,
  Description,
  ShadowBox,
  Horizontal,
} from './styles';
import greenArrow from '../../assets/greenArrow.png';

interface IProps {
  title: string;
  link: string;
  description: string;
  pubDate: string;
  source: string;
}

const News: React.FC<IProps> = (props: IProps) => {
  const { title, link, pubDate, description, source } = props;
  return (
    <Container>
      <ShadowBox href={link} target="blank">
        <Subtitle>
          <h4>{title}</h4>
          <Description>
            <p>{`${description.substring(0, 160)}...`}</p>
          </Description>
          <Horizontal>
            <p>{pubDate}</p>
            <p>{source}</p>
          </Horizontal>
        </Subtitle>
        <Icon>
          <img src={greenArrow} alt="Seta verde" />
        </Icon>
      </ShadowBox>
    </Container>
  );
};

export default News;

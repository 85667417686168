import React from 'react';
import { Container, Content } from './styles';

const Doubts: React.FC = () => {
  return (
    <Container>
      <Content>
        <h4>Ainda com dúvidas?</h4>
        <p>Entenda mais sobre Open Finance e suas fases no Portal do cidadão</p>
        <a href="https://openbankingbrasil.org.br/">
          Acessar Portal do Cidadão {'>'}{' '}
        </a>
      </Content>
    </Container>
  );
};

export default Doubts;

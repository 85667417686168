import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 0 2rem 0 2rem;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 96.4rem;

  h2 {
    margin-top: 8rem;
    margin-bottom: 4rem;
    font-weight: 700;
    color: var(--petroleum);
    font-size: 3rem;
    text-align: left;
    @media (max-width: 530px) {
      font-size: 2.2rem;
    }
  }

  section:nth-child(3) {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1.6rem 3.2rem;
      background: #ffffff;
      border: 0.1rem solid var(--lightGreen);
      color: var(--lightGreen);
      border-radius: 10rem;
      width: 28rem;
      height: 4.8rem;
    }
  }
`;

export const Feed = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
  }
`;

export const Question = styled.button`
  display: flex;
  align-items: center;
  padding: 1.6rem 3.2rem;
  background: #ffffff;
  border: 1px solid var(--mediumGray);
  box-sizing: border-box;
  border-radius: 0.8rem;
  cursor: pointer;
  margin-top: 1.6rem;
  width: 100%;

  :hover {
    background-color: var(--lightGreen);
    color: white;
  }

  :focus {
    background-color: var(--lightGreen);
    color: white;
    border-radius: 0.8rem 0.8rem 0px 0px;
  }

  :active {
    background-color: var(--lightGreen);
    color: white;
    border-radius: 0.8rem 0.8rem 0px 0px;
  }

  span {
    width: 50%;
  }

  span:nth-child(1) {
    display: flex;
    justify-content: flex-start;
  }

  span:nth-child(2) {
    display: flex;
    justify-content: flex-end;

    img {
      height: 4rem;
      width: 4rem;
      background-color: white;
      border-radius: 25px;
    }
  }
`;

export const Answer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 3.2rem;
  background-color: var(--lightGray);

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;

import React from 'react';
import Routes from './routes';
import GlobalStyle from './styles/global';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const App: React.FC = () => {
  return (
    <>
      <Routes />
      <GlobalStyle rate={62.5} />
    </>
  );
};

export default App;

import React from 'react';
import { MdLens } from 'react-icons/md';
import { Container, Content, Text, Logo, Image } from './styles';
import welcomeOpenBanking from '../../assets/welcomeOpenBanking.png';

const Welcome: React.FC = () => {
  return (
    <Container>
      <Content>
        <Text>
          <Logo>
            <h2>Bem-vindo ao</h2>
            <h1>
              <span>Open Finance</span>
              <span>
                <div>Midway!</div>
                <div>
                  <MdLens />
                </div>
              </span>
            </h1>
          </Logo>
          <p>
            Mais autonomia, liberdade e segurança para você é da nossa conta.
          </p>
        </Text>
        <Image>
          <img src={welcomeOpenBanking} alt="Welcome Open Finance" />
        </Image>
      </Content>
    </Container>
  );
};

export default Welcome;

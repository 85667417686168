import { createGlobalStyle } from 'styled-components';
import { darken, lighten } from 'polished';

interface GlobalProps {
  rate: number;
}

export default createGlobalStyle<GlobalProps>`
* {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  box-sizing: border-box;
  outline: none;
}
:root {
    --tifanny: #22C9C3;
    --lightTifanny: ${lighten(0.5, '#22C9C3')};
    --petroleum: #00595B;
    --darkPetroleum: ${darken(0.05, '#00595B')};
    --lightPetroleum:#e2feff;
    --highlights:#00b7a3;
    --white: #ffffff;
    --blackMidway: #252525;
    --errorRed: #BC4328;
    --paragraph: #1F2B2A;

    --gray: #616B6A;
    --lightGray: #F3F3F3;
    --darkGray: #1F2B2A;
    --mediumGray: #707070;

    --darkGreen: #004D49;
    --lightGreen: #00726D;

    --maxContent: 96.4rem;
    --borderGray: #606060;
    --footerGray: #D3D3D3;
    --newTifanny: #50EBE6;
    --darkTifanny: #65D3D7;
    --lightNewTifanny: rgba(80, 235, 230, 0.1);
    --greenLight: #0F988B;
    --newLightGray: #fafafa;
    --greenish: #5AF57C;
    --lightGreenish: #E6FEEB;
    --violetMidway: #8097E1;
    --lightViolet: rgba(128, 151, 225, 0.05);
}
body {
	line-height: 1;
    -webkit-font-smoothing: antialiased;
}
html {
  font-size: ${(props) => props.rate}%;
}
body, input, button {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}
button {
    cursor: pointer;
    outline: none;
    border: none;
    transition: all 0.2s;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 3rem 2.3rem;
  border-radius: 1.6rem;
  box-shadow: 0px 15px 25px -15px rgba(0, 0, 0, 0.15);
  text-align: left;

  svg {
    margin-bottom: 3rem;
    font-size: 3rem;
    color: var(--greenLight);
  }

  h3 {
    color: var(--petroleum);
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 1.2rem;

    span {
      font-weight: 600;
    }
  }

  h5 {
    margin-bottom: 2rem;
    color: var(--borderGray);
    font-size: 1.4rem !important;
    font-weight: 400;
  }

  p {
    color: var(--borderGray);
    font-size: 1.6rem !important;
    font-weight: 500;
    line-height: 2rem;
  }
`;

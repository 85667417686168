import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const NextArrow = styled.button`
  display: flex;
  color: var(--petroleum);
  width: 4rem;
  position: absolute;
  z-index: 2;
  background-color: transparent;
  font-size: 4rem;
  align-items: center;
  top: calc(50% - 6rem);

  &:disabled {
    color: var(--footerGray);
    cursor: not-allowed;
  }

  @media (max-width: 960px) {
    display: none;
    margin: 0;
    padding: 0;
  }
`;

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MobilePage from '../pages/MobilePage';
import NotFound from '../pages/NotFoundPage';
import HomePage from '../pages/HomePage';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/mobile" component={MobilePage} />
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;

import React from 'react';
import { Container, Content } from './styles';

const WhatChanges: React.FC = () => {
  return (
    <Container>
      <Content>
        <h2>O que muda para você?</h2>
        <h3>Mais transparência, melhores ofertas de produtos e serviços</h3>
        <p>
          Queremos que você tenha liberdade e autonomia para escolher as ofertas
          que atendem as suas necessidades. Com Open Finance, você poderá trazer
          seus dados financeiros para a Midway, compartilhando informações
          cadastrais, transacionais e histórico de crédito para de qualquer
          instituição para agilizar o processo de análise e liberação de crédito
          e serviços.
        </p>
      </Content>
    </Container>
  );
};

export default WhatChanges;
